.EZseLa_tryAndroidApp {
  background-color: #2196f3;
  grid-template-columns: 1fr auto;
  margin: 5px;
  display: grid;
}

.EZseLa_tryAndroidApp a {
  color: #fff;
  padding: 10px;
  text-decoration: none;
}

.EZseLa_tryAndroidApp svg {
  vertical-align: bottom;
  color: #fff;
}

.biIv0W_logo {
  height: 80px;
  margin-right: 10px;
}

.biIv0W_atChar {
  height: ".8em";
}

.biIv0W_chip {
  margin: 3px;
}

.biIv0W_content a:not(.biIv0W_chip) {
  color: #d22d40;
}

.w-Bacq_activeAnimation {
  background: linear-gradient(#fff0 49%, #2196f3 50%) 0 0 / 200% 200%;
  animation: 3s infinite alternate w-Bacq_activeAnimationKeyframes;
}

@keyframes w-Bacq_activeAnimationKeyframes {
  0% {
    background-position: 100%;
  }

  10% {
    background-position: 100% 100%;
  }

  20% {
    background-position: 100%;
  }

  30% {
    background-position: 100% 80%;
  }

  40% {
    background-position: 100%;
  }

  50% {
    background-position: 100% 70%;
  }

  60% {
    background-position: 100% 100%;
  }

  70% {
    background-position: 100%;
  }

  80% {
    background-position: 100% 90%;
  }

  90% {
    background-position: 100% 40%;
  }

  100% {
    background-position: 100% 80%;
  }
}

.g7QY5W_header {
  grid-template-columns: 1fr auto;
  place-items: center start;
  display: grid;
}

.g7QY5W_headerTitle {
  font-weight: bold;
}

.g7QY5W_historyItem {
  grid-template-columns: 64px calc(100vw - 128px) 64px;
  place-items: center start;
  display: grid;
}

.g7QY5W_listItemText {
  grid-column-gap: 0;
  grid-template-columns: repeat(4, auto) 1fr;
  align-items: center;
  width: 100%;
  padding: 0;
  display: grid;
}

.g7QY5W_valueText {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
  overflow: hidden;
}

.D1OitW_languageContainer {
  filter: saturate(.9);
  align-items: center;
  column-gap: 8px;
  font-weight: bold;
  display: flex;
}

.D1OitW_languageName {
  font-weight: bold;
}

.nwZBXW_flex {
  flex-direction: row;
  width: min(100% - 40px, 1400px);
  margin: 20px auto;
  display: flex;
}

@media (min-width: 769px) {
  .nwZBXW_flex {
    align-items: flex-start;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .nwZBXW_flex {
    flex-direction: column;
  }
}

.nwZBXW_switchButtonWrapper {
  place-self: center;
  margin: 4px;
}

@media (min-width: 769px) {
  .nwZBXW_switchButtonWrapper {
    align-self: flex-start;
    margin-top: 70px;
    transform: rotate(90deg);
  }
}

.nwZBXW_transliteration {
  word-break: break-all;
  margin-bottom: 0;
}

.nwZBXW_translationHeaderContainer {
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  padding: 0 10px;
  display: flex;
}

@media (max-width: 768px) {
  .nwZBXW_translationHeaderContainer {
    padding: 0 8px;
  }
}

.nwZBXW_label {
  font-size: .9rem;
}

.nwZBXW_translationFieldContainer {
  flex-direction: column;
  display: flex;
}

@media (min-width: 769px) {
  .nwZBXW_translationFieldContainer {
    width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .nwZBXW_translationFieldContainer {
    max-width: 100%;
  }
}

.nwZBXW_removeButton {
  top: 10px;
  right: 10px;
  position: absolute !important;
}

.nwZBXW_translationHeader {
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  min-height: 38px;
  margin: 0 8px;
  display: flex;
}

.nwZBXW_sourceInput {
  min-height: 171px;
}

.nwZBXW_translationOutput {
  overflow-wrap: break-word;
  border-radius: 1px;
  height: 100%;
  min-height: 171px;
  padding: 16px;
}

.nwZBXW_networkError {
  justify-items: center;
  display: grid;
}

.nwZBXW_loadingBar {
  margin-top: -4px;
  top: 4px;
}

.nwZBXW_translationText {
  font-weight: bold;
}

.nwZBXW_asrTempOutput {
  text-align: end;
  white-space: nowrap;
  flex-direction: row-reverse;
  width: 100%;
  margin: 0 10px;
  font-size: small;
  display: flex;
  overflow-x: hidden;
}

.iRcQWG_container {
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  display: flex;
  position: absolute;
}

.MuiAppBar-root.iRcQWG_header {
  background-color: #f9f9f9;
  width: 100%;
}

.MuiAppBar-root.iRcQWG_header .iRcQWG_MuiToolbar-root {
  padding: 4px 0 4px 12px;
}

.iRcQWG_toolbar {
  width: 100%;
  max-height: 100px;
  padding: 0 10px 0 0 !important;
}

@media (min-width: 768px) {
  .iRcQWG_toolbar {
    width: min(100% - 40px, 1400px);
    height: 70px;
    margin-inline: auto;
    padding: 0 !important;
  }
}

.iRcQWG_logo {
  width: 260px;
}

@media (max-width: 768px) {
  .iRcQWG_logo {
    margin-left: 24px;
  }
}

.iRcQWG_footer {
  text-align: center;
  color: #d22d40;
  background-color: #f9f9f9;
  margin: auto 0 0;
  padding: 8px;
  font-size: .7rem;
}

@media (max-width: 768px) {
  .iRcQWG_footer {
    font-size: .55rem;
  }
}

/*# sourceMappingURL=index.7303fae6.css.map */
